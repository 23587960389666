import {Navbar, Nav, Container } from 'react-bootstrap';

export const NavBar = () => {
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="#home">

            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">

            </Navbar.Toggle >
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#About Me">About Me</Nav.Link>
                <Nav.Link href="#Projects">Projects</Nav.Link>
                <Nav.Link href="#Resume">Resume</Nav.Link>
                <Nav.Link href="#Extras">Extras</Nav.Link>
                <Nav.Link href="#Contact">Let's Connect</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
}