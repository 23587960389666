import { Container, Col, Row, Image } from "react-bootstrap";
import image from "../assests/images/SkarAudio.png"
import "../styles/Intro.css"

export const Intro = () => {
    return (
        <Container>
            <Row>
                <Col className="wrapper-intro-left"></Col>
                <Col className="wrapper-intro-right">
                    <Image src={image} roundedCircle fluid/>
                </Col>
            </Row>
        </Container>
    );
}